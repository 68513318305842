<template>
  <div v-if="model && !isLoading">
    <app-toolbar :crumbs="crumbs">
      <v-btn
        v-if="theme.features['person-tracker'] == true"
        plain
        :to="{ name: 'person-detail', params: { personId: model.personId } }"
      >
        Tracker
      </v-btn>
      <v-btn plain @click="toggleChat()" class="d-none d-md-inline">
        <message-badge :id="personId" />
      </v-btn>
    </app-toolbar>

    <v-row>
      <v-col>
        <person-form
          v-model="model"
          :collection="collection"
          @cancel="onCancel"
          @delete="onDelete"
          @save="onSave"
        ></person-form>
      </v-col>
      <v-col v-if="layout.showChat" :cols="layout.chatCols">
        <channel-messages
          v-model="model"
          detail-route="collection-person-messages"
          entity-type="person"
          @close="toggleChat()"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import appToolbar from '@/components/layout/app-toolbar'
import channelMessages from '@/views/messaging/channel'
import collectionApi from '@/services/api/collection'
import personApiBuilder from '@/services/api/collection-person'
import personForm from './form'
import messageBadge from '@/messaging/components/message-badge'
import messageMixin from '@/components/mixins/messaging'

export default {
  mixins: [messageMixin],
  components: {
    appToolbar,
    channelMessages,
    personForm,
    messageBadge
  },
  data() {
    return {
      isLoading: true,
      collection: null,
      personApi: null,
      model: {},
      layout: {
        chatCols: 6,
        mainCols: 12,
        showChat: false
      }
    }
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    crumbs() {
      const items = [
        {
          text: this.$t('collections'),
          to: { name: 'collections-index' },
          disabled: false
        }
      ]

      if (this.collection) {
        items.push({
          text: this.collection.shortId,
          to: {
            name: 'collection-detail',
            params: { collectionId: this.collectionId }
          },
          disabled: false
        })
      }

      items.push({ text: this.$t('person'), disabled: true })
      return items
    },
    collectionId() {
      return this.$route.params.collectionId
    },
    personId() {
      return this.$route.params.personId
    }
  },
  async mounted() {
    this.personApi = personApiBuilder.build(this.collectionId)
    this.collection = await collectionApi.fetch(this.collectionId)
    this.model = await this.personApi.fetch(this.personId)
    this.isLoading = false
  },
  methods: {
    ...mapActions('messages', ['addMessage']),
    onCancel() {
      this.returnToCollection()
    },
    async onDelete() {
      await this.personApi.delete(this.personId)
      this.addMessage({
        message: `${this.$t('person')} '${this.model.name}' ${this.$t(
          'hasBeenDeleted'
        )}`
      })
      this.returnToCollection()
    },
    async onSave(item) {
      await this.personApi.put(this.personId, item)
      this.addMessage({
        message: `${this.$t('person')} '${item.name}' ${this.$t(
          'hasBeenUpdated'
        )}`
      })
      this.returnToCollection()
    },
    returnToCollection() {
      this.$router.push({
        name: 'collection-detail',
        params: { collectionId: this.collectionId }
      })
    },
    toggleChat() {
      this.layout.showChat = !this.layout.showChat
      this.layout.mainCols = this.layout.showChat ? 7 : 12
      this.layout.chatCols = this.layout.showChat ? 5 : 0
    }
  }
}
</script>

<style></style>
