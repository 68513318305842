<template>
  <div v-if="model && !isLoading">
    <app-toolbar :crumbs="crumbs" />
    <div v-if="model.status != 'Draft'"  class="mt-4 text-center mx-auto">
        This form has a status of <strong>{{ model.status }}</strong> and can not be modified.  Please create a new form if you need to make changes.
    </div>
    <component v-if="model.status == 'Draft'" :is="formComponentType" :value="model" :collection="collection" @save="onSave" @cancel="onCancel" @delete="onDelete" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import appToolbar from '@/components/layout/app-toolbar'
import collectionApi from '@/services/api/collection'
import collectionFormApiBuilder from '@/services/api/collection-form'

export default {
  components: { appToolbar },
  data() {
    return {
      isLoading: true,
      collection: null,
      model: null,
      collectionFormApi: null
    }
  },
  computed: {
    formComponentType() {
      return this.model.formType.uiComponentName
    },
    crumbs() {
      const items = [
        {
          text: this.$t('collections'),
          to: { name: 'collections-index' },
          disabled: false
        }
      ]

      if (this.collection) {
        items.push({
          text: this.collection.collectionNumber,
          to: {
            name: 'collection-detail',
            params: { collectionId: this.collectionId }
          },
          disabled: false
        })
      }

      items.push({ text: this.$t('forms'), disabled: true })
      if (this.model) {
        items.push({
          text: this.model.name,
          to: {
            name: 'collection-form-detail',
            params: { collectionId: this.collectionId, formId: this.model.id }
          },
          disabled: false
        })
      }
      return items
    },
    collectionId() {
      return this.$route.params.collectionId
    },
    formId() {
      return this.$route.params.formId
    }
  },
  async mounted() {
    this.collection = await collectionApi.fetch(this.collectionId)
    this.collectionFormApi = collectionFormApiBuilder.build(this.collectionId)
    this.model = await this.collectionFormApi.fetch(this.formId)
    this.isLoading = false
  },
  methods: {
    ...mapActions('messages', ['addMessage']),
    onCancel() {
      this.returnToCollection()
    },
    async onSave(value){
      value.status = 'Completed'
      await this.collectionFormApi.complete(this.formId, value)

      this.addMessage({
        type: 'success',
        message: this.$t('formSaved')
      })

      this.returnToCollection()
    },
    async onDelete() {
      await this.collectionFormApi.delete(this.formId)

      this.addMessage({
        type: 'success',
        message: this.$t('formDeleted')
      })

      this.returnToCollection()
    },
    returnToCollection() {
      this.$router.push({
        name: 'collection-detail',
        params: { collectionId: this.collectionId }
      })
    }
  }
}
</script>

<style></style>
