import { checkbox, defaultStyles } from '@/services/pdf-utils'

const noTopBorderLayout = {
  hLineWidth: function (i)
  {
    return (i === 0) ? 0 : 1; // Remove the top border

    // if(i !== 0){
    //   return 1;
    // }


    // if (i === 0 || i === node.table.body.length) {
    //   return 0;
    // }

    // return (i === node.table.headerRows) ? 2 : 1;
  },
  vLineWidth: function ()
  {
    return 1;
  },
  hLineColor: function ()
  {
    return 'black';
  },
  vLineColor: function ()
  {
    return 'black';
  }
};
export default {
  pageSize: 'letter',
  pageMargins: [ 20, 20, 20, 20 ],
  content: [
    {
      style: 'tableExample',
      table: {
        heights: [30, 30],
        widths: ['*', 150],
        body: [
          [
            { rowSpan: 2,  text: [
              {text:'Evidence/Property Custody Document\n', style: 'header'},
              {text:'For use of this form see AR 195-5; the proponent agency is CID', style: 'small', alignment: 'center'}
            ],  margin: [0,10, 0, 0]},
            { text: 'MPR/CID Sequence Number', style: 'fieldLabel' }
          ],
          [
            {},
            { text: 'CRD Report/CID ROI Number', style: 'fieldLabel' }
          ]
        ]
      }
    },
    {
      style: 'noTopBorder',
      layout: noTopBorderLayout,
      table: {
        heights: [30, 40, 40],
        widths: ['50%', '25%', '25%'],
        layout: noTopBorderLayout,
        body: [
          [
            { text: 'Receiving Activity', style: 'fieldLabel' },
            { text: 'Location', style: 'fieldLabel', colSpan: 2 },
            {}
          ],
          [
            [
              { text: 'Name, Grade and Title of Person from Who Received', style: 'fieldLabel' },
              {
                table: {
                  body: [
                    checkbox('Owner', false),
                    checkbox('Other', false)
                  ]
                },
                layout: 'noBorders'
              },
            ],
            { text: 'Address (Include Zip Code)', style: 'fieldLabel', colSpan:2 },
            {}
          ],
          [
            { text: 'Location From Where Obtained', style: 'fieldLabel' },

            { text: 'Time/Date Obtained', style: 'fieldLabel' },
            { text: 'Reason Obtained', style: 'fieldLabel' },

          ]
        ]
      }
    },

    /* Material */
    {
      section: 'material',
      style: 'noTopBorder',
      layout: noTopBorderLayout,

      table: {
        heights: ['auto'],
        widths: [45, 75, '*'],
        dontBreakRows: true,
        headerRows: 1,
        body: [
          [
            { text: 'Item No', style: 'fieldLabel', alignment: 'center', margin: [0,10,0,0] },
            { text: 'Quantity', style: 'fieldLabel', alignment: 'center', margin: [0,10,0,0] },
            [
                { text: 'Description of Articles', style: 'subheader2', alignment: 'center' },
                { text: '(Include model, serial number, condition and unusual marks or scratches)', style: 'fieldLabel',  alignment: 'center' }
            ]
          ]
        ]
      }
    },
    /* Chain of Custody */
    {
      section: 'chainOfCustody',
      style: 'noTopBorder',
      layout: noTopBorderLayout,
      table: {
        heights: ['auto', 25],
        widths: [35, 35, '30%', '32%', '*'],
        headerRows: 2,
        dontBreakRows: true,
        body: [
         [
            { text: 'Chain of Custody', style: 'subheader2', alignment: 'center', colSpan: 5},
            {}, {}, {}, {}
          ],
          [
            { text: 'Item No.', style: 'fieldLabel', alignment: 'center'},
            { text: 'Date', style: 'fieldLabel', alignment: 'center'},
            { text: 'Released By', style: 'fieldLabel', alignment: 'center'},
            { text: 'Received By', style: 'fieldLabel', alignment: 'center'},
            { text: 'Purpose of Change of Custody', style: 'fieldLabel', alignment: 'center'},
          ]
        ]
      }
    },
  ],
  defaultStyle: {
    fontSize: 8,
    bold: true,
    margin: [0, 0, 5, 2],
    alignment: 'left',
    opacity: 0.75
  },
  styles: {
   ...defaultStyles
  },
  images: {}
}
