import { safeText } from '@/services/pdf-utils'

export const materialRow = (material) => {

  return [
    { text: safeText(material.shortId), margin: [0, 5, 0, 0], alignment: 'center'  },
    { text: safeText(material.quantity), margin: [0, 5, 0, 0], alignment: 'center' },
    { text: safeText(material.description), margin: [0, 5, 0, 0] }
  ]
}
