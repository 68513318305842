<template>
  <div  class="d-flex justify-center ma-6" >
    <v-img contain :src="dataUrl" lazy-src="dataUrl" :alt="value.name" :title="value.name" class="mx-auto" max-height="800" max-width="1500" min-width="250" min-height="250">
    </v-img>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: null,
      default: ''
    }
  },
  computed: {
    dataUrl() {
      if (this.value.dataUrl) {
        return this.value.dataUrl
      }
      return null
    }
  }
}
</script>
