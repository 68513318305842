import i18n from '@/plugins/i18n'
import { safeText } from '@/services/pdf-utils'
import { splitArrayIntoGroups } from '@/services/utils'
import mediaService from '@/services/api/media'

export const mediaTable = async (doc, media) => {
  if (media.length == 0) {
    return null
  }

  const stack = []

  stack.push({
    text: i18n.t('mediaItems'),
    style: 'subheader2',
    margin: [0, 0, 0, 15]
  })

  const table = {
    table: {
      widths: ['33.33%', '33.33%', '33.33%'],
      headerRows: 1,
      body: []
    },

    layout: 'noBorders'
    // layout: {
    //   //hLineStyle: function (i) { return i % 2 ?  {dash: { length: 10, space: 4 }}: {} },
    //   //vLineStyle: function (i) { return i % 2 ?  {dash: { length: 10, space: 4 }}: {} },
    //   // paddingLeft: function(i, node) { return 4; },
    //   // paddingRight: function(i, node) { return 4; },
    //   // paddingTop: function(i, node) { return 2; },
    //   // paddingBottom: function(i, node) { return 2; },
    //   fillColor: function () { return 'white' }
    // }
  }

  const images = (media || []).filter(m => m.contentType.startsWith('image/'))
  const otherMedia = (media || []).filter(
    m => !m.contentType.startsWith('image/')
  )

  if (images.length > 0) {
    const mediaGroups = splitArrayIntoGroups(images, 3)
    const rows = []

    for (let i = 0; i < mediaGroups.length; i++) {
      const row = []
      const rowLabels = []

      const group = mediaGroups[i]

      for (let j = 0; j < group.length; j++) {
        const media = group[j]

        row.push(await mediaItem(doc, media))
        rowLabels.push({
          text: safeText(media.shortId),
          style: 'caption',
          margin: [0, 0, 0, 10],
          alignment: 'left',
          border: [false, false, false, false]
        })
      }

      while (row.length < 3) {
        row.push({
          text: '',
          width: '33.33%',
          border: [false, false, false, false]
        }),
        rowLabels.push({
          text: '',
          style: 'caption',
          alignment: 'left',
          border: [false, false, false, false]
        })
      }
      rows.push(row)
      rows.push(rowLabels)
    }

    table.table.body.push(...rows)
  }
  stack.push(table)

  if (otherMedia.length > 0) {
    stack.push({
      text: i18n.t('otherMedia'),
      style: 'subheader2',
      margin: [0, 10]
    })

    const list = []
    for (let i = 0; i < otherMedia.length; i++) {
      const media = otherMedia[i]
      list.push(`${media.shortId} - ${media.contentType.split('/')[1]}`)
    }
    stack.push({ ul: list, style: 'caption' })
  }

  return stack
  // table.table.body.push(stack)
  // return table
}
/*
 * Build talbe of media items
 */
export const mediaTableStacked = async (doc, media) => {
  if (media.length == 0) {
    return null
  }

  const stack = []

  stack.push({
    text: i18n.t('mediaItems'),
    style: 'subheader2',
    margin: [0, 0, 0, 15]
  })

  const images = (media || []).filter(m => m.contentType.startsWith('image/'))
  const otherMedia = (media || []).filter(
    m => !m.contentType.startsWith('image/')
  )

  if (images.length > 0) {
    const mediaGroups = splitArrayIntoGroups(images, 3)
    const rows = []

    for (let i = 0; i < mediaGroups.length; i++) {
      const row = []
      const group = mediaGroups[i]

      for (let j = 0; j < group.length; j++) {
        const media = group[j]

        row.push(await mediaItem(doc, media))
      }

      while (row.length < 3) {
        row.push({
          text: '',
          width: '33.33%'
        })
      }
      rows.push(row)
    }

    const table = {
      table: {
        widths: ['33.33%', '33.33%', '33.33%'],
        body: rows
      },
      layout: 'noBorders'
    }

    stack.push(table)
  }

  if (otherMedia.length > 0) {
    stack.push({
      text: i18n.t('otherMedia'),
      style: 'subheader2',
      margin: [0, 10]
    })

    const list = []
    for (let i = 0; i < otherMedia.length; i++) {
      const media = otherMedia[i]
      list.push(`${media.shortId} - ${media.contentType.split('/')[1]}`)
    }
    stack.push({ ul: list, style: 'caption' })
  }

  return stack
}

/*
 * Build a single media item card
 */
export const mediaItem = async (doc, media) => {
  try {
    const dataUrl = await mediaService.getBase64Url(media.id)
    doc.images[media.id] = dataUrl

    return {
      stack: [{ image: media.id, fit: [165, 165], alignment: 'left' }]
    }
  } catch (e) {
    console.log('mediaItem', e)
    return {
      stack: [
        { text: i18n.t('missingMedia'), style: 'caption', alignment: 'center' }
      ]
    }
  }
}
