<template>
  <div>
    <v-img
      :height="height"
      :max-height="maxHeight"
      :max-width="maxWidth"
      :src="value.dataUrl"
      class="grey lighten-2"
    >
      <template v-slot:placeholder>
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
      </template>
    </v-img>
  </div>
</template>

<script>
export default {
  props: {
    height: {
      type: Number,
      default: 150
    },
    width: {
      default: '100%'
    },
    maxHeight: { default: '75px' },
    maxWidth: { default: null },
    value: {
      type: null,
      default: ''
    }
  },
  data() {
    return {
      innerValue: '',
      loading: false
    }
  }
}
</script>
