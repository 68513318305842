export const safeText = text => {
  return text || ''
}

/*
 * Field label and value
 */
export const field = (label, value, style) => {
  style = style || 'fieldValue'
  return {
    stack: [
      { text: safeText(label), style: 'fieldLabel' },
      { text: safeText(value) }
    ],
    style: style
  }
}

/*
 * Content wrapped in a table to provide a border around the content
 */
export const contentWithBorder = (content, padding, borderColor) => {
  padding = padding || 0
  borderColor = borderColor || 'gray'

  const paddingFunc = () => {
    return padding
  }
  const borderColorFunc = () => {
    return borderColor
  }

  return {
    table: {
      widths: '*',
      body: [[content]]
    },
    layout: {
      paddingLeft: paddingFunc,
      paddingRight: paddingFunc,
      paddingTop: paddingFunc,
      paddingBottom: paddingFunc,
      hLineColor: borderColorFunc,
      vLineColor: borderColorFunc
    }
  }
}

/*
* Create a checkbox with a label
*/
export const checkbox = (label, checked) => {
  const checkedValue = checked ?

          {
            type: 'rect',
            x: 2, y: 2,
            w: 6,
            h: 6,
            r: 0,
            color: 'black'
          }
    : {};

  return [
    {
      canvas: [
        {
          type: 'rect',
          x: 0, y: 0,
          w: 10,
          h: 10,
          r: 0,
          lineColor: 'black',
          lineWidth: 1
        },
        checkedValue
      ],
      width: 15,
      margin: [0, 5, 0, 0]
    },
    { text: safeText(label), margin: [0, 5, 0, 5], style: 'fieldLabel' }
  ]
}

/*
* Default Styles
*/
export const defaultStyles = {
  header: {
    fontSize: 14,
    bold: true,
    margin: [0, 0, 0, 0],
    alignment: 'center'
  },
  subheader: {
    bold: false,
    fontSize: 12,
    margin: [0, 0, 0, 4]
  },
  subheader2: {
    bold: true,
    fontSize: 12,
    margin: [0, 0, 0, 0],
    alignment: 'center'
  },
  pageFooter: {
    fontSize: 8,
    alignment: 'center',
    margin: [0, 0, 10, 15],
    opacity: 0.75
  },
  pageHeader: {
    fontSize: 8,
    margin: [0, 5, 10, 15],
    opacity: 0.75
  },
  body: {
    fontSize: 10
  },
  caption: {
    fontSize: 10,
    opacity: 0.75
  },
  small: {
    fontSize: 8
  },
  fieldLabel: {
    fontSize: 8,
    bold: true,
    margin: [0, 0, 5, 2],
    alignment: 'left',
    opacity: 0.75
  },
  fieldValue: {
    fontSize: 10,
    bold: false,
    margin: [0, 0, 0, 5],
    alignment: 'left'
  },
  table2: {
    margin: [0, 0, 0, 0]
  }
}
