<template>
  <div v-if="model">
    <v-form ref="form" v-model="valid" lazy-validation>
      <br/>

      <material-list :value="collection.material" :collection="collection" @selected="onMaterialSelected"></material-list>

      <v-card flat tile>
        <v-card-actions>
            <!-- <confirm-dialog
              title="Delete Form?"
              text="Are you sure you want to delete this form? This action cannot be undone."
              @confirm="onDelete"
            ></confirm-dialog> -->
          <v-spacer></v-spacer>
          <v-btn plain color="default" @click="onCancel">{{
              $t('cancel')
            }}</v-btn>
          <v-btn tile large color="primary" @click="onSave" >
            {{ $t('create') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// import confirmDialog from '@/components/dialogs/confirmation'
import materialList from './components/material'
import { exportFormAndOpen } from './pdf'

export default {
  components: {
    // confirmDialog,
    materialList
  },
  props: {
    value: {
      type: null,
      default: ''
    },
    collection:{
      type: Object,
      required: true
    }
  },
  data()
  {
    return {
      model: null,
      valid: true
    }
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    ...mapGetters('user', ['profile']),
    isValid(){
      return this.valid && this.model.content.material.length > 0
    }

  },
  mounted()
  {
    this.load(this.value)
  },
  watch: {
    value(value)
    {
      this.load(value)
    },
  },
  methods: {
    load(model){
      if(!model.content){
        model.content = {material: []}
      }
      this.model = model
      this.model.content.material = []
    },
    onMaterialSelected(selected){
      this.model.content.material = [...selected]
      console.log('material selected', { selected: [...selected], model: this.model.content.material })
    },
    onExport(){

    },
    async onSave()
    {
      this.$refs.form.validate()
      if (!this.valid)
      {
        return
      }

      const options = {
        theme: {...this.theme},
        profile: {...this.profile}
      }

      exportFormAndOpen(this.model, options)
      //Todo: save form to database

      this.$emit('save', this.model)
    },
    onDelete(){
      this.$emit('delete', this.model)
    },
    onCancel()
    {
      this.$emit('cancel')
    }
  }
}
</script>
