import { safeText } from '@/services/pdf-utils'
/*
 *
 * @param {Object} model - The model for the cover page.  Required properties:
 *   - title
 *   - name
 *   - organization
 *   - preparedBy?
 */
export const coverPage = async (doc, model) => {
  const image = model.logo
    ? {
        image: model.logo,
        width: 75,
        alignment: 'center',
        margin: [0, 0, 0, 10]
      }
    : null
  const page = [
    {
      stack: [
        image,
        { text: safeText(model.title) },
        { text: safeText(model.name), style: 'coverPageTitle' }
      ],
      style: 'coverPageHeader'
    },
    {
      stack: [
        { text: safeText(model.organization) },
        {
          text: [
            { text: 'Prepared by: ', bold: true },
            { text: safeText(model.preparedBy) }
          ]
        }
      ],
      style: 'coverPageFooter',
      pageBreak: 'after'
    }
  ]

  doc.content.push(...page)
  doc.styles = { ...doc.styles, ...styles }

  return page
}

const styles = {
  coverPageHeader: {
    fontSize: 14,
    bold: true,
    alignment: 'center',
    margin: [0, 120, 0, 80]
  },
  coverPageTitle: {
    fontSize: 22,
    margin: [0, 10, 0, 0]
  },
  coverPageFooter: {
    fontSize: 12,
    alignment: 'center',
    lineHeight: 1.5,
    margin: [0, 300, 0, 0]
  }
}
