class RequestQueue {
  _controller = null

  constructor() {}

  start() {
    this._controller = new AbortController()
  }

  cancelAll() {
    this._controller?.abort()
  }

  get signal() {
    if (!this._controller) {
      this.start()
    }

    return this._controller?.signal
  }
}

const queue = new RequestQueue()

export default queue

class RequestQueueClass {
  constructor(maxConcurrentRequests = 10) {
      this.queue = []
      this.maxConcurrentRequests = maxConcurrentRequests
      this.activeRequests = 0
      this.uniqueRequests = new Map()
  }

  enqueue(requestFn, key) {

    if (this.uniqueRequests.has(key)) {
      return this.uniqueRequests.get(key)
    }

    const promise = new Promise((resolve, reject) => {
        this.queue.push({ requestFn, resolve, reject })
        this.processQueue()
    });

    this.uniqueRequests.set(key, promise)
    return promise
  }

  async processQueue() {
      if (this.activeRequests < this.maxConcurrentRequests && this.queue.length > 0) {
          const { requestFn, resolve, reject } = this.queue.shift()
          this.activeRequests++

          try {
              const result = await requestFn()
              resolve(result)
          } catch (error) {
              reject(error)
          } finally {
              this.activeRequests--
              this.processQueue()
          }
      }
  }
}

const requestQueue =  new RequestQueueClass()
export {
  requestQueue
}
