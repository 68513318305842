<template>
  <div>
    <h4>Material</h4>
    <div>Select the Material you would like to include in the generated form</div>
    <v-data-table
      v-if="model"
      v-model="selected"
      :headers="headers"
      :items="model"
      :disable-pagination="true"
      item-key="id"
      show-select
      hide-default-footer
      class="elevation-1"
    >
    </v-data-table>
    <!-- <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left" style="width:100px">Item No.</th>
            <th class="text-center" style="width:75px">Quantity</th>
            <th class="text-center">
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in model" :key="item.id">
            <td class="text-left no-wrap">{{ item.shortId }}</td>
            <td class="text-center">{{ item.quantity }}</td>
            <td class="text-left">{{ item.description }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <pre>{{ selected }}</pre>-->
  </div>

</template>

<script>
export default{
  props: {
    value: {
      type: null,
      default: ''
    },
    collection:{
      type: Object,
      required: true
    }
  },
  data()
  {
    return {
      model: null,
      selected: [],
      valid: true,
      headers: [
        {
          text: 'Item No.',
          align: 'start',
          sortable: false,
          value: 'shortId',
        },
        { text: 'Quantity', value: 'quantity' },
        { text: 'Description', value: 'description' },
      ]
    }
  },
  mounted()
  {
    this.load(this.value)
  },
  watch: {
    value(value)
    {
      this.load(value)
    },
    selected(value){
      this.$emit('selected', value)
    }
  },
  methods: {
    load(model){
      if(!model){
        model = []
      }
      this.model = model
    }
  }
}
</script>
