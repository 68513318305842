<template>
  <div>
    <v-card flat tile>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12">
              <v-text-field
                :label="`${$t('name')}*`"
                required
                v-model="model.name"
                :rules="[v => !!v || $t('nameRequired')]"
              ></v-text-field>
            </v-col>
            <v-col v-if="enableCollectionTypes" cols="12">
              <v-select
                v-model="model.collectionType"
                :label="`${$t('collectionType')}*`"
                :items="collectionTypes"
                item-text="label"
                item-value="value"
              >
              </v-select>
            </v-col>
            <v-col cols="12">
              <v-textarea
                name="input-7-1"
                :label="`${$t('description')}*`"
                auto-grow
                v-model="model.description"
              ></v-textarea>
            </v-col>
            <v-col cols="12" sm="6">
              <datetime-picker
                :label="`${$t('eventDate')}*`"
                required
                v-model="model.eventDateTime"
                :rules="[v => !!v || $t('eventDateRequired')]"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <location-picker v-model="location" format="mgrs" />
            </v-col>
            <v-col cols="6"></v-col>
            <v-col cols="12">
              <v-text-field
                :label="$t('collectionNumber')"
                placeholder="[DTG]-[MGRS]-[USER]"
                v-model="model.collectionNumber"
                readonly
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn plain color="default" @click="onCancel">{{
          $t('cancel')
        }}</v-btn>
        <v-btn tile large color="primary" @click="onSave" :disabled="!valid">
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import datetimePicker from '@/components/datetime/datetime-picker.menu'
import locationPicker from '@/components/maps/location-picker'
import { getCollectionTypes } from '@/services/utils'

export default {
  components: { datetimePicker, locationPicker },
  props: {
    item: { type: Object, required: true },
    label: { type: String, default: 'Create' },
    options: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      location: null,
      model: {},
      valid: true
    }
  },
  computed: {
    ...mapGetters('user', ['userCode']),
    ...mapGetters('theme', ['theme']),
    collectionTypes() {
      return getCollectionTypes()
    },
    enableCollectionTypes() {
      return this.theme.features['collection-types'] ==  true
    },
    isNew() {
      return this.model.id ? true : false
    }
  },
  mounted() {
    this.model = _.cloneDeep(this.item)
    this.setModelData()
  },
  watch: {
    item(newVal) {
      if (newVal) {
        this.model = _.cloneDeep(newVal)
      } else {
        this.model = {}
      }
      this.setModelData()
    },
    location: {
      deep: true,
      handler(newVal) {
        this.model.latitude = newVal.latitude
        this.model.longitude = newVal.longitude
        this.model.mgrs = newVal.mgrs
      }
    }
  },
  methods: {
    onCancel() {
      this.$emit('cancel')
    },
    onSave() {
      this.$refs.form.validate()
      if (!this.valid) {
        return
      }

      this.$emit('save', this.model)
    },
    setModelData() {
      this.location = {
        latitude: this.model.latitude,
        longitude: this.model.longitude,
        mgrs: this.model.mgrs
      }
    }
  }
}
</script>

<style></style>
