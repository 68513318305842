export const chainOfCustodyRow = () => {

  return [
    [
      {rowSpan: 2, text:''},
      {rowSpan: 2, text:''},
      {text: 'Name, Grade or Title', style: 'fieldLabel'},
      {text: 'Name, Grade or Title', style: 'fieldLabel'},
      {rowSpan: 2, text:''},
    ],
    [
        {},
        {},
        {text: 'Signature', style: 'fieldLabel'},
        {text: 'Signature', style: 'fieldLabel'},
        {},
    ],
  ]
}
