export const finalDispositionAction = () => {

  return [
    { text: 'Final Disposition Action', style: 'subheader2', alignment:'center', margin:[0, 10, 0,0] },
    {
      table: {
        widths: ['*'],
        heights: [30, 30, 30],
        body: [
          [
            { text: 'Release to Owner or Other', style: 'fieldLabel' }
          ],
          [
              { text: 'Destroy', style: 'fieldValue' }
          ],
          [
              { text: 'Other (Specify):', style: 'fieldValue' }
          ]
        ]
      }
    },
    { text: 'Final Dispositional Authority', style: 'subheader2', alignment:'center', margin:[0, 10, 0,0] },
    { text: [
        {text: 'Items(s)___________________________ ON THIS DOCUMENT, PERTAINING TO THE INVESTIGATION INVOLVING _____________________________________________\n'},
        {text: '___________________________, _________________________________ (IS) (ARE) NO LONGER REQUIRED AS EVIDENCE AND MAY BE DSIPPOSED OF AS INDICATED ABOVE( if articles(s) must be released, do not sign, but explain in separate correspondence)\n'}
        ]
    },
     {
      table: {
        widths: ['*', '*', 50],
        heights: [30, 30, 30],
        body: [
          [
            { text: 'Name/Grade/Title', style: 'fieldLabel' },
              { text: 'Signature', style: 'fieldValue' },
              { text: 'Date', style: 'fieldValue' }
          ]
        ]
      }
    },
  ]
}

export const witnessToDestruction = () => {

  return [
    { text: 'Witness to Destruction of Evidence', style: 'subheader2', alignment:'center', margin:[0, 10, 0,0] },
    { text: [
      { text: 'THE ARTICLES(S) LISTED AT ITEM NUMBER(S)____________________________________ (WAS) (WERE) DESTROYED BYE THE EVIDENCE CUSTODIAL IN MY PRESENCE ON THE DATE INDICATED ABOVE\n'},
    ]},
    {
      table: {
        widths: ['*', '*', 50],
        heights: [30, 30, 30],
        body: [
          [
            { text: 'Signature', style: 'fieldValue' },
            { text: 'Name / Organization', style: 'fieldLabel' },
            { text: 'Date', style: 'fieldValue' }
          ]
        ]
      }
    },
  ]
}

