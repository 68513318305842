<template>
  <div v-if="model && !isLoading">
    <app-toolbar :crumbs="crumbs" />

    <v-card flat tile>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation v-if="formTypes">
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="model.formTypeId"
                :label="`${$t('formType')}*`"
                :items="formTypes"
                item-text="name"
                item-value="id"
                required/>
            </v-col>
            <v-col cols="12">
              <v-text-field
                :label="`${$t('name')}`"
                required
                v-model="model.name"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn plain color="default" @click="onCancel">{{
          $t('cancel')
        }}</v-btn>
        <v-btn tile large color="primary" @click="onSave" :disabled="!isValid">
          {{ $t('next') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import appToolbar from '@/components/layout/app-toolbar'
import collectionApi from '@/services/api/collection'
import formTypes from '@/services/api/form-types'
import colelctionFormApiBuilder from '@/services/api/collection-form'

export default {
  components: { appToolbar },
  data() {
    return {
      isLoading: true,
      collection: null,
      collectionFormApi: null,
      model: {},
      valid: true
    }
  },
  computed: {
    crumbs() {
      const items = [
        {
          text: this.$t('collections'),
          to: { name: 'collections-index' },
          disabled: false
        }
      ]

      if (this.collection) {
        items.push({
          text: this.collection.collectionNumber,
          to: {
            name: 'collection-detail',
            params: { collectionId: this.collectionId }
          },
          disabled: false
        })
      }

      items.push({ text: this.$t('forms'), disabled: true })
      return items
    },
    collectionId() {
      return this.$route.params.collectionId
    },
    formId() {
      return this.$route.params.formId
    },
    isValid(){
      return this.valid && this.model.formTypeId
    }
  },
  async mounted() {

    this.collection = await collectionApi.fetch(this.collectionId)
    this.formTypes = await formTypes.lookups()
    this.collectionFormApi = colelctionFormApiBuilder.build(this.collectionId)

    this.isLoading = false
  },
  methods: {
    ...mapActions('messages', ['addMessage']),
    onCancel() {
      this.returnToCollection()
    },
    async onSave(){
      try{
        const response = await this.collectionFormApi.post(this.model)
        this.addMessage({
          message: this.$t('formCreated'),
          type: 'success'
        })

        console.log('form create', response)
        this.$router.push({
          name: 'collection-form-detail',
          params: { collectionId: this.collectionId, formId: response.data.id }
        })
      } catch (error) {
          this.addError(error)
      }
    },
    returnToCollection() {
      this.$router.push({
        name: 'collection-detail',
        params: { collectionId: this.collectionId }
      })
    }
  }
}
</script>

<style></style>
