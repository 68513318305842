<template>
  <div class="media-preview" v-if="innerValue || dataUrl">
    <v-hover v-slot="{ hover }">
      <v-card
        :flat="flat"
        :elevation="hover && canHover ? 12 : 0"
        :class="{ 'on-hover': hover && canHover }"
        :max-height="height"
        :max-width="width"
        :width="width"
        @click.stop="dialog = true"
      >
        <media-thumbnail
          v-if="dataUrl"
          v-model="innerValue"
          :height="height"
          :width="width"
          :max-height="maxHeight"
        />
      </v-card>
    </v-hover>

    <v-dialog v-model="dialog" v-if="dialog" fullscreen>
      <v-card height="100%">
        <v-toolbar color="primary" flat dark>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ fullValue.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="downloadOriginal">
            <v-icon >mdi-download</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="mx-auto">
          <v-row
            v-if="!fullValue.dataUrl"
            class="fill-height ma-8"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="grey lighten-2"
              size="60"
            ></v-progress-circular>
          </v-row>
          <component v-if="fullValue.dataUrl" :is="viewerComponentType" :value="fullValue" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import mediaApi from '@/services/api/media'
import mediaThumbnail from './media-thumbnail'
import { getComponentForContentType } from '@/services/media.service'

export default {
  components: { mediaThumbnail },
  props: {
    canHover: {
      type: Boolean,
      default: true
    },
    flat: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      default: 150
    },
    maxHeight: {
      type: Number,
      default: 150
    },
    width: {
      default: '100%'
    },
    value: {
      type: null,
      default: ''
    }
  },
  data() {
    return {
      dialog: false,
      innerValue: '',
      fullValue: {}
    }
  },
  computed: {
    dataUrl() {
      if (this.innerValue.dataUrl) {
        return this.innerValue.dataUrl
      }
      return null
    },
    viewerComponentType() {
      return getComponentForContentType(this.innerValue.contentType, 'viewer')
    }
  },
  watch: {
    dialog(value){
      if(value && this.innerValue.id){
        console.log('loading full value')
        this.loadFullImage(this.innerValue.id)
      }
    },
    innerValue(value) {
      if (!value.dataUrl) {
        this.loadImage(value.id)
        this.$emit('input', value)
      }
    },
    value(value) {
      if (value != this.innerValue) {
        this.innerValue = value
      }
    }
  },
  methods: {
    async loadImage(itemId) {
      const response = await mediaApi.getBase64Url(itemId, { size: 'medium' })
      if (response) {
        this.$set(this.innerValue, 'dataUrl', response)
      }
    },
    async loadFullImage(itemId, size){
      size = size || 'large'

      const response = await mediaApi.getBase64Url(itemId, { size })
      if (response) {
        this.$set(this.fullValue, 'dataUrl', response)
        this.$set(this.fullValue, 'name', this.innerValue.name)
      }
    },
    view() {
      window.open(this.dataUrl, '_blank')
    },
    async downloadOriginal() {
      try {
        const url = await mediaApi.getDownloadUrl(this.innerValue.id)
        window.open(url.url)
      } catch (error) {
        console.log('error', error)
      }
    }
  },
  mounted() {},
  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  }
}
</script>

<style>
.media-preview .on-hover {
  cursor: pointer;
}
</style>
