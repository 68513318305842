import apiClient from '@/services/api/clients/api-client.js'
import { API_URL } from '@/config'

const path = `${API_URL}/formtypes`

const client = apiClient.build(path)

client.lookups = async function () {
  const response = await this.get();
  return response.data;

}

export default client
